import authChangePassword from './authChangePassword.json'
import authForgotPassword from './authForgotPassword.json'
import authLogin from './authLogin.json'
import authResetPassword from './authResetPassword.json'
import authSetupPassword from './authSetupPassword.json'
import bonus from './bonus.json'
import common from './common.json'
import currency from './currency.json'
import dashboard from './dashboard.json'
import email from './email.json'
import error from './error.json'
import faq from './faq.json'
import form from './form.json'
import formMy from './formMy.json'
import generation from './generation.json'
import logActivity from './logActivity.json'
import member from './member.json'
import order from './order.json'
import payment from './payment.json'
import privacyPolicy from './privacyPolicy.json'
import profile from './profile.json'
import promo from './promo.json'
import report from './report.json'
import role from './role.json'
import sponsor from './sponsor.json'
import support from './support.json'
import termsCondition from './termsCondition.json'
import userManagement from './userManagement.json'
import validation from './validation.json'

export default {
  authChangePassword,
  authForgotPassword,
  authLogin,
  authResetPassword,
  authSetupPassword,
  common,
  dashboard,
  error,
  form,
  formMy,
  generation,
  payment,
  profile,
  report,
  role,
  sponsor,
  userManagement,
  validation,
  member,
  order,
  currency,
  faq,
  promo,
  bonus,
  email,
  termsCondition,
  privacyPolicy,
  logActivity,
  support,
}
